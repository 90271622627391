//closest polyfill
if (!Element.prototype.matches) {
  Element.prototype.matches =
  Element.prototype.msMatchesSelector ||
  Element.prototype.webkitMatchesSelector;
}
if (!Element.prototype.closest) {
  Element.prototype.closest = function (s) {
    var el = this;
    do {
      if (Element.prototype.matches.call(el, s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
};

document.addEventListener("turbolinks:load", function () {
  let navMenu = document.getElementById("nav-menu");

  if (navMenu) {
    let navMenuButton = navMenu.querySelector("button.nav-menu-button");
    let open = false;

    navMenuButton.addEventListener('click', () => {
      toggleMenu();
    });

    document.addEventListener('keydown', (e) => {
      if (e.keyCode === 27 && open) { //esc
        toggleMenu();
      }
    });

    document.addEventListener('click', (e) => {
      if (!e.target.closest('#nav-menu')) {
        toggleMenu();
      }
    });

    function toggleMenu() {
      open = !open;
      navMenu.classList.toggle('nav-menu-open');
      navMenu.classList.toggle('nav-menu-closed');
    }
  };
});
