const MAX_USER_VALUE = 200;

document.addEventListener("turbolinks:load", function () {
  let priceSlider = document.getElementById("price-slider");

  if (priceSlider) {
    let proContainer       = priceSlider.querySelector(".pro-plan");
    let largeTeamContainer = priceSlider.querySelector(".large-team");

    let control      = priceSlider.querySelector("input[type=range]");
    let userCount    = priceSlider.querySelector(".user-count");
    let priceDisplay = priceSlider.querySelector(".price-amount");

    let basePrice          = parseInt(priceSlider.dataset.basePrice, 10);
    let perUserPrice       = parseInt(priceSlider.dataset.perUserPrice, 10);
    let includedUsersCount = parseInt(priceSlider.dataset.includedUsersCount, 10);

    control.addEventListener("input", () => {
      let userValue = control.value;

      if (userValue > MAX_USER_VALUE) {
        showLargeTeam();
      } else {
        showProPlan();
        userCount.innerHTML = userValue 
        let price = basePrice + (Math.abs(control.value - includedUsersCount) * perUserPrice);
        priceDisplay.innerHTML = `$${price / 100}`
      }
    });

    function showProPlan() {
      proContainer.style.display = "";
      largeTeamContainer.style.display = "none";
    }

    function showLargeTeam() {
      proContainer.style.display = "none";
      largeTeamContainer.style.display = "";
    }
  }
});