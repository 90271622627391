import { debounce } from 'lodash';

import "./from-polyfill";

if (!("scrollBehavior" in document.documentElement.style)) {
  import("scroll-behavior-polyfill");
}

const CAROUSEL_AUTOSCROLL_INTERVAL = 5000;
document.addEventListener("turbolinks:load", function () {
  let carousel = document.querySelector(".carousel");

  if (carousel) {
    let items = carousel.querySelectorAll(".item");
    let viewport = carousel.querySelector(".carousel-viewport");
    let controls = Array.from(carousel.querySelectorAll(".controls > div"));
    let currentItem = 0;

    controls[0].classList.add("bg-gray-800");

    function scrollTo(index) {
      let scrollSize = items[0].clientWidth;
      let delta = index - currentItem; 

      // scroll to the middle of the destination element
      scrollSize = (delta * scrollSize) - (scrollSize / 2) + 1;

      viewport.scrollBy({
        left: scrollSize,
        behavior: "smooth"
      });
    }

    function autoScroll() {
      if (currentItem >= items.length - 1) {
        scrollTo(0);
      } else {
        scrollTo(currentItem + 1)
      }
    }

    let autoScrollerInterval;
    
    function startAutoScroll() {
      autoScrollerInterval = setInterval(autoScroll, CAROUSEL_AUTOSCROLL_INTERVAL);
    }

    function stopAutoScroll() {
      clearInterval(autoScrollerInterval);
    }

    viewport.addEventListener("mouseenter", () => {
      stopAutoScroll();
    });

    viewport.addEventListener("mouseleave", () => {
      startAutoScroll();
    });

    let scrollHandler = debounce(() => {

      // detect current item
      let itemSize = items[0].clientWidth;
      currentItem = Math.floor(viewport.scrollLeft / itemSize);

      controls.forEach(c => c.classList.remove("bg-gray-800"));
      let currentControl = controls.find(c => c.dataset.index === currentItem.toString())
      currentControl.classList.add("bg-gray-800");

    }, 50);

    viewport.addEventListener("scroll", scrollHandler);

    controls.forEach((c) => {
      c.addEventListener("click", (e) => {
        let controlIndex = e.target.dataset.index;
        scrollTo(controlIndex);

        stopAutoScroll();

        e.preventDefault();
        return false;
      });
    });

    startAutoScroll();
  }
});